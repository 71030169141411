import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import toArray from "@/scripts/helpers/dom/toArray";

class SelectListDialog extends Plugin {
  defaults() {
    return {
      itemSelector: "[data-select-list-dialog-item]",
      valueAttr: "data-select-list-dialog-value",
      descriptionAttr: "data-select-list-dialog-description",
      activeClassName: "is-active",
      populateButtonSelector: "[data-select-list-dialog-button-populate]",
      populateInputIdAttr: "data-select-list-dialog-input-id"
    };
  }

  init() {}

  buildCache() {
    const {
      itemSelector,
      populateButtonSelector,
      populateInputIdAttr
    } = this.options;

    this.items = toArray(this.element.querySelectorAll(itemSelector));
    this.populateBtn = this.element.querySelector(populateButtonSelector);
    this.inputId = this.element.getAttribute(populateInputIdAttr);
    this.value = null;
    this.description = null;
  }

  bindEvents() {
    this.items.forEach(item =>
      item.addEventListener("click", () => this.handleItemClick(item))
    );

    this.populateBtn.addEventListener("click", () =>
      this.handlePopulateButtonClick()
    );
  }

  handleItemClick(item) {
    const { valueAttr, descriptionAttr } = this.options;
    this.setItemActive(item);
    this.value = item.getAttribute(valueAttr);
    this.description = item.getAttribute(descriptionAttr);
  }

  setItemActive(item) {
    const { activeClassName } = this.options;

    this.items.forEach(item => item.classList.remove(activeClassName));
    item.classList.add(activeClassName);
  }

  handlePopulateButtonClick() {
    if (this.inputId && this.value) {
      this.events.emit("input.populate", {
        id: this.inputId,
        value: this.value,
        description: this.description
      });
    }
  }
}

export default init(SelectListDialog, "select-list-dialog");
