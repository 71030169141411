import MicroModal from "micromodal";

const execYoutubeCommand = (frame, command) => {
  if (frame.contentWindow === null) {
    return;
  }

  frame.contentWindow.postMessage(
    window.JSON.stringify({ event: "command", func: command }),
    "https://www.youtube.com"
  );
};

const modalHandler = (modal, command) => {
  const iframes = modal.querySelectorAll("iframe");

  [].forEach.call(iframes, frame => {
    execYoutubeCommand(frame, command);
  });
};

const preventOverscroll = event => {
  event.preventDefault();
};

const config = {
  awaitCloseAnimation: true,
  onClose: modal => {
    modalHandler(modal, "pauseVideo");
    document.documentElement.classList.remove("scroll-locked");
    document.body.removeEventListener("touchmove", preventOverscroll, false);
  },
  onShow: modal => {
    modal.scrollTop = 0;
    // modal.scrollTop = 1000;
    modalHandler(modal, "playVideo");
    document.documentElement.classList.add("scroll-locked");
    document.body.addEventListener("touchmove", preventOverscroll, false);
  }
};

export default {
  init: () => MicroModal.init(config),
  show: id => MicroModal.show(id, config),
  close: id => MicroModal.close(id, config)
};
