import throttle from "lodash/throttle";
import ease from "ease-component";

import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import scrollPageTo from "@/scripts/helpers/scrollTo";
import getOffsetTop from "@/scripts/helpers/dom/getOffsetTop";

class ScrollTo extends Plugin {
  defaults() {
    return {
      offsetAttr: "data-scroll-to-offset", // number or "center"
      updateUrlAttr: "data-scroll-to-update-url",
      duration: 500
    };
  }

  init() {
    this.buildCache();
    this.bindEvents();
    this.updateViewportHeight();
  }

  getViewportHeight() {
    return parseInt(document.documentElement.clientHeight, 10);
  }

  updateViewportHeight() {
    this.viewportHeight = this.getViewportHeight();
  }

  buildCache() {
    this.viewportHeight = 0;
    this.offset = this.element.getAttribute(this.options.offsetAttr) || 0;
    this.updateUrl =
      this.element.getAttribute(this.options.updateUrlAttr) === "true";
  }

  bindEvents() {
    window.addEventListener(
      "resize",
      throttle(this.updateViewportHeight.bind(this), 150)
    );

    this.element.addEventListener("click", event => this.handleClick(event));
  }

  isLink(el) {
    return el.hasAttribute("href") && el.getAttribute("href").length;
  }

  handleClick(event) {
    event.preventDefault();

    if (!this.isLink(this.element)) return;

    const title = this.element.innerText;
    const url = this.element.getAttribute("href");
    const hash = url.split("#")[1];
    const target = document.querySelector(`a[name="${hash}"], #${hash}`);

    let offset = 0;

    if (target) {
      const targetTop = getOffsetTop(target);

      if (this.offset === "center") {
        const targetHeight = parseInt(target.offsetHeight, 10);

        if (targetHeight < this.viewportHeight) {
          offset = Math.ceil((this.viewportHeight - targetHeight) / 2);
        }
      } else {
        offset = parseInt(this.offset, 10);
      }

      let options = {
        duration: this.options.duration,
        ease: ease.inOutCube
      };
      const to = targetTop - offset;

      scrollPageTo({ to, options });

      if (this.updateUrl) {
        if (window.history.pushState) {
          window.history.pushState(null, title, url);
        } else {
          window.location = url;
        }
      }
    }
  }
}

export default init(ScrollTo, "scroll-to");
