import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import attrs from "attrs";
import generateId from "@/scripts/helpers/generateId";
import { transitionEndEventName } from "@/scripts/helpers/event/aninmation";
import { KEYCODES } from "@/scripts/helpers/constants";

class SearchWidget extends Plugin {
  defaults() {
    return {
      resultsSelector: "[data-search-widget-results]",
      inputSelector: "[data-search-widget-input]",
      openAttr: "data-search-widget-open",
      minQueryLength: 3,
      clearOnExit: true,
      inputTimeout: 300,
      outerClickClose: true,
      resultsClickClose: true,
      onChange(value) {
        console.log("onChange()", { value });
      }
    };
  }

  init() {
    this.setA11y();
  }

  buildCache() {
    const { inputSelector, resultsSelector } = this.options;
    this.input = this.element.querySelector(inputSelector);
    this.results = this.element.querySelector(resultsSelector);
    this.resultsId = generateId();
    this.transitionEvent = transitionEndEventName();
    this.timeout = null;
  }

  bindEvents() {
    this.input.addEventListener("keydown", event => this.handleKeydown(event));
    this.input.addEventListener("input", event => this.handleInput(event));
    document.addEventListener("click", event => this.handleOuterClick(event));
    this.results.addEventListener("click", () => this.handleResultsClick());
  }

  setA11y() {
    this.element.setAttribute(this.options.openAttr, "false");
    this.results.style.display = "none";

    attrs(this.input, {
      autocomplete: "off",
      role: "combobox",
      "aria-autocomplete": "list",
      "aria-owns": this.resultsId,
      "aria-required": "true",
      "aria-expanded": "false"
    });

    attrs(this.results, {
      id: this.resultsId,
      role: "listbox",
      "aria-atomic": "true",
      "aria-busy": "false",
      "aria-live": "polite"
    });
  }

  show() {
    this.element.setAttribute(this.options.openAttr, "true");
    this.input.setAttribute("aria-expanded", "true");
    this.results.style.display = "";
  }

  hide() {
    this.element.setAttribute(this.options.openAttr, "false");
    this.input.setAttribute("aria-expanded", "false");

    const transitionEndCallback = event => {
      this.results.removeEventListener(
        this.transitionEvent,
        transitionEndCallback
      );

      if (event.target === this.results) {
        this.results.style.display = "none";
      }
    };

    this.results.addEventListener(this.transitionEvent, transitionEndCallback);
  }

  get isOpen() {
    return this.element.getAttribute(this.options.openAttr) === "true";
  }

  get isDisabled() {
    return (
      this.element.disabled ||
      this.element.getAttribute("aria-disabled") === "true" ||
      false
    );
  }

  handleKeydown(event) {
    const { target, which } = event;

    if (which === KEYCODES.ESC) {
      if (this.isOpen) {
        this.hide();
        this.input.blur();
      }

      if (this.options.clearOnExit) {
        target.value = "";
      }
    }
  }

  handleInput(event) {
    const { target } = event;

    if (this.isDisabled) {
      return;
    }

    this.stopTimer();

    if (target.value.length >= this.options.minQueryLength) {
      this.startTimer(() => {
        this.callback("onChange", this, this.input.value);
        this.show();
      });
    } else {
      if (this.isOpen) {
        this.hide();
      }
    }
  }

  handleOuterClick(event) {
    if (this.options.outerClickClose && this.isOpen) {
      const isClickInside = this.element.contains(event.target);
      if (!isClickInside) {
        this.hide();
      }
    }
  }

  handleResultsClick() {
    if (this.options.resultsClickClose && this.isOpen) {
      this.hide();
    }
  }

  stopTimer() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  startTimer(cb) {
    this.timeout = setTimeout(cb, this.options.inputTimeout);
  }
}

export default init(SearchWidget, "search-widget");
