import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { toArray } from "@/scripts/helpers/dom/toArray";

class DropdownMenu extends Plugin {
  defaults() {
    return {
      itemSelector: "[data-dropdown-menu-item]",
      valueAttr: "data-dropdown-menu-value",
      dropdownIdAttr: "data-dropdown-id",
      activeItemClassName: "is-active",
      hasPlaceholderAttr: "data-dropdown-menu-placeholder",
      hasPlaceholder: false,
      onBeforeChange(meta) {
        return meta;
      }
    };
  }

  init() {}

  get hasPlaceholder() {
    const hasPlaceholder = this.element.getAttribute(
      this.options.hasPlaceholderAttr
    );
    return hasPlaceholder ? !!hasPlaceholder : this.options.hasPlaceholder;
  }

  buildCache() {
    this.items = toArray(
      this.element.querySelectorAll(this.options.itemSelector)
    );
    this.dropdownId = this.element.getAttribute(this.options.dropdownIdAttr);
  }

  bindEvents() {
    this.items.forEach(item => {
      item.addEventListener("click", () => this.itemClickHandler(item));
    });

    this.events.on("dropdown.init", meta => this.handleInitEvent(meta));
  }

  itemClickHandler(item) {
    this.select(item);
  }

  select(item) {
    const { valueAttr } = this.options;
    const value = item.hasAttribute(valueAttr)
      ? item.getAttribute(valueAttr)
      : "";

    let dupItem = item.children[0].cloneNode(true);

    if (value && this.dropdownId) {
      const meta = {
        id: this.dropdownId,
        value,
        trigger: dupItem,
        close: true
      };

      this.selectMenuItem(item);
      const changedMeta = this.callback("onBeforeChange", this, meta);
      this.events.emit("dropdown.change", changedMeta);
    }
  }

  selectMenuItem(item) {
    const { activeItemClassName } = this.options;
    this.items.forEach(item => item.classList.remove(activeItemClassName));
    item.classList.add(activeItemClassName);
  }

  handleInitEvent({ id, mode }) {
    if (id === this.dropdownId && mode === "select") {
      const item = this.getSelectedItem();

      if (item) {
        this.select(item);
      }
    }
  }

  getSelectedItem() {
    if (this.items.length > 0) {
      const active = this.items.filter(item =>
        item.classList.contains(this.options.activeItemClassName)
      );

      if (this.hasPlaceholder) {
        return active.length > 0 ? active[0] : null;
      }

      return active.length > 0 ? active[0] : this.items[0];
    }

    return null;
  }
}

export default init(DropdownMenu, "dropdown-menu");
