import classNames from "classnames";
import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { h } from "@/scripts/helpers/dom/createElement";
import Modal from "@/modules/modal/modal";

class InputPopulate extends Plugin {
  defaults() {
    return {
      buttonClassName: "input__button input__button_populate",
      buttonText: "Найти и выбрать",
      buttonAdditionalClassNames: ["button"],
      dialogIdAttr: "data-input-populate-dialog-id"
    };
  }

  init() {
    this.renderButton();
  }

  buildCache() {
    const {
      buttonClassName,
      buttonAdditionalClassNames,
      buttonText,
      dialogIdAttr
    } = this.options;

    this.input = this.element.querySelector("input");
    this.button = (
      <button
        type="button"
        title={buttonText}
        disabled={this.isDisabled}
        className={classNames(buttonClassName, buttonAdditionalClassNames)}
      >
        <span className="visuallyhidden">{buttonText}</span>
      </button>
    );

    this.container = this.element.querySelector(".input__container");
    this.dialogId = this.input.getAttribute(dialogIdAttr);
    this.inputId = this.input.getAttribute("id");
    this.description = this.element.appendChild(
      <span className="input__description" style="display: none;" />
    );
  }

  bindEvents() {
    this.button.addEventListener("click", () => this.handleClick());
    this.events.on("input.populate", meta =>
      this.handleInputPopulateEvent(meta)
    );

    this.input.addEventListener("input", () => this.handleInputChange());
  }

  get isDisabled() {
    return !!this.input.disabled;
  }

  handleClick() {
    if (this.isDisabled) {
      this.button.disabled = true;
      return;
    }

    this.button.disabled = false;

    this.openDialog();
  }

  handleInputPopulateEvent({ id, value, description }) {
    if (this.inputId === id && value) {
      this.input.value = value;

      if (description && description.length > 0) {
        this.description.innerText = description;
        this.description.style = "";
      }
    }
  }

  handleInputChange() {
    this.description.style.display = "none";
  }

  renderButton() {
    this.container.appendChild(this.button);
  }

  openDialog() {
    if (this.dialogId) {
      Modal.show(this.dialogId);
    }
  }
}

export default init(InputPopulate, "input-populate");
