import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import parseSeconds from "@/scripts/helpers/date-time/parseSeconds";
import leadingZero from "@/scripts/helpers/date-time/leadingZero";

class ConfirmationCounter extends Plugin {
  defaults() {
    return {
      messageSelector: "[data-confirmation-counter-message]",
      counterSelector: "[data-confirmation-counter]",
      resendSelector: "[data-confirmation-counter-resend]",
      cooldown: 160,
      onStart() {
        // console.log("ConfirmationCounter onStart()");
      },
      onStop() {
        // console.log("ConfirmationCounter onStop()");
      }
    };
  }

  init() {
    this.start(this.options.cooldown);
  }

  buildCache() {
    const { messageSelector, counterSelector, resendSelector } = this.options;
    this.message = this.element.querySelector(messageSelector);
    this.counter = this.element.querySelector(counterSelector);
    this.resend = this.element.querySelector(resendSelector);
  }

  bindEvents() {}

  start(cooldown = 0) {
    this.callback("onStart", this);

    const interval = parseInt(cooldown, 10) * 1000;
    let time = cooldown;

    this.updateCounter(cooldown);
    this.showMessage();

    const timer = setInterval(() => {
      this.updateCounter(--time);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      this.stop();
    }, interval);
  }

  stop() {
    this.hideMessage();
    this.callback("onStop", this);
  }

  showMessage() {
    this.message.style.display = "block";
    this.resend.style.display = "none";
  }

  hideMessage() {
    this.message.style.display = "none";
    this.resend.style.display = "block";
  }

  updateCounter(cooldown) {
    this.counter.innerText = this.formatCounter(cooldown);
  }

  formatCounter(totalSeconds) {
    let time = "";

    if (totalSeconds > 60) {
      const { h, m, s } = parseSeconds(totalSeconds);

      time += h ? `${h}:` : "";
      time += m ? `${h ? leadingZero(m) : m}:` : "";
      time += m ? leadingZero(s) : s;

      return time;
    }

    return `${totalSeconds} сек`;
  }
}

export default init(ConfirmationCounter, "confirmation-counter");
