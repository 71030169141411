import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";

class Header extends Plugin {
  defaults() {
    return {
      menuOverScrolledClassName: "header_menu_fixed"
    };
  }

  init() {}

  buildCache() {
    this.lastScrollTop = 0;
  }

  bindEvents() {
    this.events.on("page.scrolled", data => this.handlePageScrolled(data));
  }

  handlePageScrolled({ top }) {
    const headerHeight = parseInt(this.element.offsetHeight, 10);
    const isOverScrolled = top >= headerHeight || false;

    if (isOverScrolled) {
      // Detecting scroll direction
      if (top > this.lastScrollTop) {
        // downscroll
        // console.log("downscroll")
        this.showMenu();
      } else {
        // upscroll
        // console.log("upscroll")
        this.hideMenu();
      }
    } else {
      this.hideMenu();
    }

    // For Mobile or negative scrolling
    this.lastScrollTop = top <= 0 ? 0 : top;

    this.events.emit("header.menu.fixed", isOverScrolled);
  }

  showMenu() {
    if (!this.isMenuVisible()) {
      this.element.classList.add(this.options.menuOverScrolledClassName);
    }
  }

  hideMenu() {
    if (this.isMenuVisible()) {
      this.element.classList.remove(this.options.menuOverScrolledClassName);
    }
  }

  isMenuVisible() {
    return this.element.classList.contains(
      this.options.menuOverScrolledClassName
    );
  }
}

export default init(Header, "header");
