import classNames from "classnames";
import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { h } from "@/scripts/helpers/dom/createElement";
import { translitToggle } from "@/scripts/helpers/transliterate";

class InputTransliterate extends Plugin {
  defaults() {
    return {
      buttonClassName: "input__button input__button_transliterate",
      buttonText: "Транслитерировать",
      buttonAdditionalClassNames: ["button"]
    };
  }

  init() {
    this.renderButton();
  }

  buildCache() {
    const {
      buttonClassName,
      buttonAdditionalClassNames,
      buttonText
    } = this.options;

    this.input = this.element.querySelector("input");
    this.button = (
      <button
        type="button"
        title={buttonText}
        disabled={this.isDisabled}
        className={classNames(buttonClassName, buttonAdditionalClassNames)}
      >
        <span className="visuallyhidden">{buttonText}</span>
      </button>
    );
  }

  get isDisabled() {
    return !!this.input.disabled;
  }

  bindEvents() {
    this.button.addEventListener("click", () => this.handleClick());
  }

  handleClick() {
    if (this.isDisabled) {
      this.button.disabled = true;
      return;
    }

    this.button.disabled = false;

    const value = this.input.value;
    this.input.value = translitToggle(value);
  }

  renderButton() {
    this.element.appendChild(this.button);
  }
}

export default init(InputTransliterate, "input-transliterate");
