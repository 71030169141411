import FlexSearch from "flexsearch/flexsearch";
import scroll from "scroll";

import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import toArray from "@/scripts/helpers/dom/toArray";
import getScrollParent from "@/scripts/helpers/dom/getScrollParent";

class SearchDropdownMenu extends Plugin {
  defaults() {
    return {
      listItemSelector: "[data-search-dropdown-menu-item]",
      filterSelector: "[data-search-dropdown-menu-filter]",
      valueAttribute: "data-dropdown-menu-value",
      dropdownIdAttr: "data-dropdown-id",
      resultsLimit: 25
    };
  }

  init() {
    this.addItemsToIndex();
  }

  buildCache() {
    const { listItemSelector, filterSelector, dropdownIdAttr } = this.options;

    this.items = toArray(this.element.querySelectorAll(listItemSelector));
    this.filter = this.element.querySelector(filterSelector);
    this.scrollParent = getScrollParent(this.element);
    this.index = new FlexSearch({
      encode: false,
      split: /\s+/,
      tokenize: "reverse"
    });
    this.dropdownId = this.element.getAttribute(dropdownIdAttr);
  }

  bindEvents() {
    if (this.filter) {
      this.filter.addEventListener(
        "keyup",
        event => this.handleFilterKeyUp(event),
        false
      );
    }

    if (this.items) {
      this.items.forEach(item =>
        item.addEventListener("click", () => this.handleItemClick(item), false)
      );
    }

    this.events.on("dropdown.open", meta => this.handleDropdownOpen(meta));
  }

  addItemsToIndex() {
    if (this.items) {
      this.items.forEach(item => {
        const value = item.getAttribute(this.options.valueAttribute);
        const text = item.innerText
          .replace(/(?:\r\n|\r|\n)/g, "")
          .replace(/ +(?= )/g, "")
          .trim()
          .toLowerCase();

        if (value && text) {
          this.index.add(value, text);
        }
      });
    }
  }

  handleFilterKeyUp(event) {
    const { value } = event.target;
    const results = this.index.search(value);

    if (value.length > 0) {
      this.filterItems(results);
    } else {
      this.showAllItems();
    }
  }

  handleItemClick(item) {
    this.showAllItems();
    this.filter.value = "";
  }

  handleDropdownOpen({ id }) {
    if (id === this.dropdownId) {
      const item = this.getActiveItem();

      this.filter.focus();

      this.scrollParent.scrollTop = item.offsetTop - item.offsetHeight;
    }
  }

  getActiveItem() {
    const active = this.items.filter(item =>
      item.classList.contains("is-active")
    );
    return active.length > 0 ? active[0] : this.items[0];
  }

  filterItems(values = []) {
    this.items.forEach(item => {
      const value = item.getAttribute(this.options.valueAttribute) || "";

      if (values.includes(value)) {
        item.style.display = "block";
      } else {
        item.style.display = "none";
      }
    });
  }

  showAllItems() {
    this.items.forEach(item => {
      item.style.display = "block";
    });
  }
}

export default init(SearchDropdownMenu, "search-dropdown-menu");
