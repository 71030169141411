import getStyleComputedProperty from "./getStyleComputedProperty";
import getParentNode from "./getParentNode";

/**
 * Returns the scrolling parent of the given element
 * @method
 * @argument {Element} element
 * @returns {Element} scroll parent
 */
export default function getScrollParent(element) {
  if (!element) {
    return document.body;
  }

  // eslint-disable-next-line default-case
  switch (element.nodeName) {
    case "HTML":
    case "BODY":
      return element.ownerDocument.body;
    case "#document":
      return element.body;
  }

  const { overflow, overflowX, overflowY } = getStyleComputedProperty(element);
  if (/(auto|scroll|overlay)/.test(overflow + overflowY + overflowX)) {
    return element;
  }

  return getScrollParent(getParentNode(element));
}
