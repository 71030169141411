import Popper from "popper.js";
import attrs from "attrs";

import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { KEYCODES } from "@/scripts/helpers/constants";
import toArray from "@/scripts/helpers/dom/toArray";

class Dropdown extends Plugin {
  defaults() {
    return {
      outerClickClose: true,
      menuClickClose: false,
      triggerSelector: `[data-dropdown-trigger]`,
      triggerContentSelector: `[data-dropdown-trigger-content]`,
      menuSelector: `[data-dropdown-menu]`,
      focusableElements: "*[tabindex], a[href]",
      menuOpenAttribute: "data-dropdown-open",
      modeAttr: "data-dropdown-mode",
      closeButtonSelector: "[data-dropdown-close]",
      inputSelector: "[data-dropdown-input]"
    };
  }

  init() {
    this.setA11yAttrs();
    this.emitInit();

    if (this.isDisabled) {
      this.setDisabledState();
    }
  }

  buildCache() {
    const {
      triggerSelector,
      menuSelector,
      focusableElements,
      triggerContentSelector,
      modeAttr,
      closeButtonSelector,
      inputSelector
    } = this.options;

    this.trigger = this.element.querySelector(triggerSelector);
    this.dropMenu = this.element.querySelector(menuSelector);
    this.closeButtons = toArray(
      this.element.querySelectorAll(closeButtonSelector)
    );
    this.focusableElements = toArray(
      this.dropMenu.querySelectorAll(focusableElements)
    );
    this.selected = 0;
    this.dropDownId = this.element.getAttribute("id") || "";
    this.triggerContent = this.element.querySelector(triggerContentSelector);
    this.input = this.element.querySelector(inputSelector);
    this.mode = this.element.getAttribute(modeAttr) || "";

    this.popper = null;
  }

  bindEvents() {
    const targets = [this.trigger, ...this.focusableElements];

    targets.forEach(element => {
      element.addEventListener("keydown", event => this.handleKeydown(event));
    });

    this.trigger.addEventListener("click", event => this.toggle(event));
    this.dropMenu.addEventListener("click", () => this.dropMenuClickHandler());
    document.addEventListener("click", event => this.outerClickHandler(event));
    this.events.on("dropdown.change", meta => this.handleChangeEvent(meta));
    this.closeButtons.forEach(button =>
      button.addEventListener("click", () => this.handleCloseButtonClick())
    );
  }

  setupPopper() {
    let placementClass;
    this.popper = new Popper(this.element, this.dropMenu, {
      placement: "bottom-start",

      modifiers: {
        flip: {
          enabled: true
        },

        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent"
        },

        offset: {
          offset: "0,6px"
        }
      },
      onUpdate: data => {
        const newPlacementClass = `placement-on-${data.placement}`;

        if (placementClass !== newPlacementClass) {
          this.element.classList.remove(placementClass);
          this.element.classList.add(newPlacementClass);

          placementClass = newPlacementClass;
        }
      },
      onCreate: data => {
        placementClass = `placement-on-${data.placement}`;
        this.element.classList.add(placementClass);
      }
    });
  }

  open() {
    if (!this.isOpen) {
      this.setupPopper();
      this.element.setAttribute(this.options.menuOpenAttribute, true);
      this.trigger.setAttribute("aria-expanded", true);
      this.events.emit("dropdown.open", {
        id: this.dropDownId,
        mode: this.mode,
        element: this.element
      });
    }
  }

  close() {
    this.element.setAttribute(this.options.menuOpenAttribute, false);
    this.trigger.setAttribute("aria-expanded", false);
    this.events.emit("dropdown.close", {
      id: this.dropDownId,
      mode: this.mode,
      element: this.element
    });
  }

  get isOpen() {
    return this.element.getAttribute(this.options.menuOpenAttribute) === "true";
  }

  get isDisabled() {
    return (
      this.element.disabled ||
      this.element.getAttribute("aria-disabled") === "true" ||
      false
    );
  }

  get isSelectMode() {
    return this.mode === "select";
  }

  toggle() {
    this.isOpen ? this.close() : this.open();
  }

  outerClickHandler(event) {
    if (this.options.outerClickClose && this.isOpen) {
      const isClickInside = this.element.contains(event.target);
      if (!isClickInside) {
        this.close();
      }
    }
  }

  handleChangeEvent({ id, value = "", trigger = null, close = false }) {
    if (id && id === this.dropDownId) {
      if (value) {
        this.setValue(value);
      }

      if (trigger) {
        this.renderTriggerContent(trigger);
      }

      if (close) {
        this.close();
      }
    }
  }

  dropMenuClickHandler() {
    if (this.options.menuClickClose) {
      this.close();
    }
  }

  handleCloseButtonClick() {
    this.close();
  }

  handleKeydown(event) {
    const focusableLength = this.focusableElements.length;
    const which = event.which;

    if (this.isDisabled) {
      return;
    }

    if (which === KEYCODES.ESC) {
      this.trigger.focus();
      this.close();
      return;
    }

    switch (which) {
      case KEYCODES.ENTER:
      case KEYCODES.SPACE:
        this.close();
        break;

      case KEYCODES.UP_ARROW:
      case KEYCODES.LEFT_ARROW:
        event.preventDefault();
        this.open();

        if (this.selected === 0) {
          this.selected = focusableLength - 1;
        } else {
          this.selected--;
        }
        break;

      case KEYCODES.DOWN_ARROW:
      case KEYCODES.RIGHT_ARROW:
        event.preventDefault();
        this.open();

        if (this.selected === focusableLength - 1) {
          this.selected = 0;
        } else {
          this.selected++;
        }

        break;

      default:
        break;
    }

    this.focusableElements[this.selected].focus();
  }

  setA11yAttrs() {
    this.element.setAttribute(this.options.menuOpenAttribute, false);

    attrs(this.trigger, {
      "aria-haspopup": true,
      "aria-expanded": false
    });
  }

  setDisabledState() {
    this.element.setAttribute("aria-disabled", true);
    this.trigger.setAttribute("disabled", true);
  }

  setValue(value) {
    if (this.input) {
      this.input.value = value;
    }
  }

  renderTriggerContent(html) {
    if (this.triggerContent) {
      this.triggerContent.innerHTML = html.outerHTML;
    }
  }

  emitInit() {
    this.events.emit("dropdown.init", {
      id: this.dropDownId,
      mode: this.mode
    });
  }
}

export default init(Dropdown, "dropdown");
