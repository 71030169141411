import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { h } from "@/scripts/helpers/dom/createElement";

class InputDatepicker extends Plugin {
  defaults() {
    return {
      locale: Russian,
      dateFormat: "d.m.Y",
      mode: "single",
      allowInput: true,
      prevArrow:
        "<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M9.414 12l7.293 7.293-1.414 1.414L6.586 12l8.707-8.707 1.414 1.414L9.414 12z'/></svg>",
      nextArrow:
        "<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14.586 12L7.293 4.707l1.414-1.414L17.414 12l-8.707 8.707-1.414-1.414L14.586 12z'/></svg>",
      ...this.getDataOptions()
    };
  }

  init() {}

  buildCache() {
    this.input = this.element.querySelector("input");
    this.element.appendChild(<span className="input__datepicker" />);
    this.picker = flatpickr(this.input, this.options);
  }

  bindEvents() {
    this.input.addEventListener("blur", () => this.handleInputBlur());
  }

  handleInputBlur() {
    if (this.picker) {
      this.picker.setDate(this.input.value, true);
    }
  }

  getDataOptions() {
    const options = this.element.getAttribute("data-input-datepicker-options");

    const parseJSON = (str, cb) => {
      try {
        return JSON.parse(options);
      } catch (error) {
        if (typeof cb === "function") {
          cb();
        }
        return null;
      }
    };

    const htmlEntitiesDecode = str =>
      String(str)
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "");

    if (options) {
      const parsed = parseJSON(htmlEntitiesDecode(options));

      return parsed || {};
    }

    return {};
  }

  validateMode(mode) {
    const defaultMode = "single";

    return mode === "single" || mode === "multiple" || mode === "range"
      ? mode
      : defaultMode;
  }
}

export default init(InputDatepicker, "input-datepicker");
