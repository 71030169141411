import { slideDown, slideUp } from "slide-anim";
import attrs from "attrs";

import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import toArray from "@/scripts/helpers/dom/toArray";

class SchedulerForm extends Plugin {
  defaults() {
    return {
      openAttribute: "data-scheduler-form-open",
      showHideSelector: "[data-scheduler-form-show-hide]",
      bodySelector: "[data-scheduler-form-body]",
      repeatTypeSelector: "[data-scheduler-form-repeat-type]",
      repeatFormAttr: "[data-scheduler-form-repeat]",
      groupAttr: "data-scheduler-group"
    };
  }

  init() {
    this.setBodyState();
    this.setGroupsState(false);
  }

  buildCache() {
    const {
      showHideSelector,
      bodySelector,
      repeatTypeSelector,
      groupAttr
    } = this.options;

    this.showHide = this.element.querySelector(showHideSelector);
    this.body = this.element.querySelector(bodySelector);
    this.repeatType = this.element.querySelector(repeatTypeSelector);
    this.groups = toArray(this.element.querySelectorAll(`[${groupAttr}]`));
  }

  bindEvents() {
    this.showHide.addEventListener("change", () => this.handleShowHideChange());
    this.repeatType.addEventListener("change", () => this.handleTypeChange());
  }

  handleShowHideChange() {
    if (this.body) {
      this.toggle();
    }
  }

  handleTypeChange() {
    this.setGroupsState();
  }

  get isOpen() {
    return this.showHide.checked;
  }

  toggle() {
    if (this.isOpen) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    attrs(this.body, {
      "aria-hidden": false,
      tabindex: "0"
    });

    attrs(this.element, {
      [this.options.openAttribute]: true
    });

    slideDown(this.body);
  }

  hide() {
    attrs(this.body, {
      "aria-hidden": true,
      tabindex: "-1"
    });

    attrs(this.element, {
      [this.options.openAttribute]: false
    });

    slideUp(this.body);
  }

  setBodyState() {
    this.body.style.display = this.showHide.checked ? "block" : "none";
  }

  setGroupsState(animate = true) {
    const type = this.repeatType.value;

    if (this.groups) {
      this.groups.forEach(group => {
        if (group.getAttribute(this.options.groupAttr) === type) {
          animate ? slideDown(group) : (group.style.display = "block");
        } else {
          animate ? slideUp(group) : (group.style.display = "none");
        }
      });
    }
  }
}

export default init(SchedulerForm, "scheduler-form");
