import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { slideDown, slideUp } from "slide-anim";
import generateId from "@/scripts/helpers/generateId";
import attrs from "attrs";

class CollapsiblePanel extends Plugin {
  defaults() {
    return {
      triggerSelector: "[data-collapsible-panel-trigger]",
      bodySelector: "[data-collapsible-panel-body]",
      openAttribute: "data-collapsible-panel-open"
    };
  }

  init() {
    this.a11yInit();
    // this.open();
  }

  buildCache() {
    const { triggerSelector, bodySelector } = this.options;

    this.bodyId = "body-" + generateId();
    this.triggerId = "trigger-" + generateId();
    this.trigger = this.element.querySelector(triggerSelector);
    this.body = this.element.querySelector(bodySelector);
  }

  bindEvents() {
    this.trigger.addEventListener("click", () => this.onClickHandler());
  }

  onClickHandler() {
    this.isOpen() ? this.close() : this.open();
  }

  a11yInit() {
    attrs(this.trigger, {
      id: this.triggerId,
      "aria-controls": this.bodyId
    });

    attrs(this.body, {
      id: this.bodyId,
      role: "region",
      "aria-labelledby": this.triggerId
    });
  }

  open() {
    attrs(this.trigger, {
      "aria-expanded": true
    });

    attrs(this.body, {
      "aria-hidden": false,
      tabindex: "0"
    });

    attrs(this.element, {
      [this.options.openAttribute]: true
    });

    slideDown(this.body);
  }

  close() {
    attrs(this.trigger, {
      "aria-expanded": false
    });

    attrs(this.body, {
      "aria-hidden": true,
      tabindex: "-1"
    });

    attrs(this.element, {
      [this.options.openAttribute]: false
    });

    slideUp(this.body);
  }

  isOpen() {
    return this.element.getAttribute(this.options.openAttribute) === "true";
  }
}

export default init(CollapsiblePanel, "collapsible-panel");
