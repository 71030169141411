import svg4everybody from "svg4everybody";
import SVGInjector from "svg-injector";

import * as serviceWorker from "./helpers/serviceWorker";

import ready from "@/scripts/helpers/dom/ready";

import Page from "@/modules/page/page";
import Header from "@/modules/header/header";
import Browsehappy from "@/modules/browsehappy/browsehappy";
import Offcanvas from "@/modules/offcanvas/offcanvas";
import Modal from "@/modules/modal/modal";
import ScrollTo from "@/modules/scroll-to/scroll-to";
import Tooltip from "@/modules/tooltip/tooltip";
import Table from "@/modules/table/table";
import Alert from "@/modules/alert/alert";
import File from "@/modules/file/file";
import Dropdown from "@/modules/dropdown/dropdown";
import ConfirmationCounter from "@/modules/confirmation-counter/confirmation-counter";
import CollapsiblePanel from "@/modules/collapsible-panel/collapsible-panel";
import SearchWidget from "@/modules/search-widget/search-widget";
import DropdownMenu from "@/modules/dropdown-menu/dropdown-menu";
import InputPassword from "@/modules/input/input-password";
import InputTransliterate from "@/modules/input/input-transliterate";
import InputDatepicker from "@/modules/input/input-datepicker";
import SchedulerForm from "@/modules/scheduler-form/scheduler-form";
import SearchDropdownMenu from "@/modules/search-dropdown-menu/search-dropdown-menu";
import InputPopulate from "@/modules/input/input-populate";
import SelectListDialog from "@/modules/select-list-dialog/select-list-dialog";
import Accordion from "@/modules/accordion/accordion";
import Tabs from "@/modules/tabs/tabs";
import Notification from "@/modules/notification/notification";
import ProgressBar from "@/modules/progress-bar/progress-bar";
import StarRating from "@/modules/star-rating/star-rating";
import AccountCardsListItem from "@/modules/account-cards-list-item/account-cards-list-item";

const InlineSVG = selector => {
  if (typeof selector === "string") {
    return SVGInjector(document.querySelectorAll(selector));
  }
};

Page(".page");
Header(".header");
Browsehappy(".browsehappy");
Offcanvas(".page");
Modal.init();
ScrollTo("[data-scroll-to]");
Tooltip("[data-tooltip]");
Table(".operations-table", {
  cellInnerClassName: "operations-table__content"
});
Table(".table_responsive");
Alert(".alert");
File(".file");
InputPassword(".input_type_password");
InputTransliterate(".input_style_transliterate");
InputDatepicker(".input_style_datepicker");
InputPopulate(".input_style_populate");
ConfirmationCounter(".confirmation-counter");
CollapsiblePanel(".collapsible-panel");
SearchWidget(".search-widget");
DropdownMenu(".bank-account-menu", {
  hasPlaceholder: true,
  onBeforeChange(meta) {
    const data = Object.assign({}, meta);
    data.trigger.classList.remove("account-list-item_style_dropdown-menu");
    data.trigger.classList.add("account-list-item_style_dropdown-trigger");
    return data;
  }
});
DropdownMenu(".language-switcher");
DropdownMenu(".dropdown-menu");
Dropdown(".dropdown");
SchedulerForm(".scheduler-form");
SearchDropdownMenu(".search-dropdown-menu");
SelectListDialog(".select-list-dialog");
InlineSVG(".app-button__image");
Accordion(".accordion");
Tabs(".tabs");
ProgressBar(".progress-bar");
StarRating(".star-rating");
AccountCardsListItem(".account-cards-list-item");

ready(function() {
  svg4everybody();
});

// If you want your app to work offline and load faster, you can change
// `unregister()` to `register()` below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

const utils = { ready };
export {
  utils,
  Page,
  Header,
  Browsehappy,
  Offcanvas,
  Modal,
  ScrollTo,
  Tooltip,
  Table,
  Alert,
  File,
  Dropdown,
  ConfirmationCounter,
  CollapsiblePanel,
  SearchWidget,
  DropdownMenu,
  InputPassword,
  InputTransliterate,
  InputDatepicker,
  InputPopulate,
  SchedulerForm,
  SearchDropdownMenu,
  SelectListDialog,
  InlineSVG,
  Accordion,
  Tabs,
  Notification,
  ProgressBar,
  StarRating
};
