const parseSeconds = _totalSeconds => {
  let totalSeconds = Math.round(_totalSeconds);

  var hours = Math.floor(totalSeconds / (60 * 60));

  var minutesDivisor = totalSeconds % (60 * 60);
  var minutes = Math.floor(minutesDivisor / 60);

  var secondsDivisor = minutesDivisor % 60;
  var seconds = Math.ceil(secondsDivisor);

  return {
    h: hours,
    m: minutes,
    s: seconds
  };
};

export default parseSeconds;
