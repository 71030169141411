import throttle from "lodash/throttle";
import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";

class Page extends Plugin {
  defaults() {
    return {
      scrolledClassName: "page_scrolled"
    };
  }

  init() {
    this.scrollHandler();
  }

  bindEvents() {
    window.addEventListener(
      "scroll",
      throttle(this.scrollHandler.bind(this), 150)
    );
  }

  scrollHandler() {
    let top = Math.abs(document.body.getBoundingClientRect().y);
    let scrolled = false;

    if (top > 0) {
      scrolled = true;
    }

    this.element.classList.toggle(this.options.scrolledClassName, scrolled);

    this.events.emit("page.scrolled", { top });
  }
}

export default init(Page, "page");
