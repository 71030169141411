import transliterateEngine from "translitit-engine";

const cyrToLatTable = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "yo",
  ж: "zh",
  з: "z",
  и: "i",
  й: "j",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "kh",
  ц: "cz",
  ч: "ch",
  ш: "sh",
  щ: "shh",
  ъ: "``",
  ы: "y`",
  ь: "`",
  э: "e`",
  ю: "yu",
  я: "ya",
  "’": "'",
  "№": "#",
  А: "A",
  Б: "B",
  В: "V",
  Г: "G",
  Д: "D",
  Е: "E",
  Ж: "Zh",
  З: "Z",
  И: "I",
  Й: "J",
  К: "K",
  Л: "L",
  М: "M",
  Н: "N",
  О: "O",
  П: "P",
  Р: "R",
  С: "S",
  Т: "T",
  У: "U",
  Ф: "F",
  Х: "Kh",
  Ц: "Cz",
  Ч: "Ch",
  Ш: "Sh",
  Ъ: "``",
  Ы: "Y`",
  Ь: "`",
  Э: "E`",
  Ю: "Yu",
  Я: "Ya"
};

const latToCyrTable = {
  a: "а",
  b: "б",
  v: "в",
  g: "г",
  d: "д",
  e: "е",
  yo: "ё",
  ye: "є",
  zh: "ж",
  z: "з",
  i: "и",
  j: "й",
  k: "к",
  l: "л",
  m: "м",
  n: "н",
  o: "о",
  p: "п",
  r: "р",
  s: "с",
  t: "т",
  u: "у",
  f: "ф",
  kh: "х",
  cz: "ц",
  ch: "ч",
  sh: "ш",
  shh: "щ",
  "``": "ъ",
  "y`": "ы",
  "`": "ь",
  "e`": "э",
  yu: "ю",
  ya: "я",
  "'": "’",
  "#": "№",
  A: "А",
  B: "Б",
  V: "В",
  G: "Г",
  D: "Д",
  E: "Е",
  Yo: "Ё",
  Zh: "Ж",
  Z: "З",
  I: "И",
  J: "Й",
  K: "К",
  L: "Л",
  M: "М",
  N: "Н",
  O: "О",
  P: "П",
  R: "Р",
  S: "С",
  T: "Т",
  U: "У",
  F: "Ф",
  Kh: "Х",
  Cz: "Ц",
  Ch: "Ч",
  Sh: "Ш",
  Shh: "Щ",
  "``": "ъ",
  "Y`": "Ы",
  "`": "ь",
  "E`": "Э",
  Yu: "Ю",
  Ya: "Я"
};

const cyrToLatTransitter = transliterateEngine(cyrToLatTable);
const latToCyrTransitter = transliterateEngine(latToCyrTable);

export const cyrToLat = str => cyrToLatTransitter(str);
export const latToCyr = str => latToCyrTransitter(str);
export const isCyrillic = str => /[а-яА-ЯЁё]/.test(str);
export const isUpperCaseSymbol = symbol => symbol === symbol.toUpperCase();
export const translitToggle = str =>
  isCyrillic(str) ? cyrToLat(str) : latToCyr(str);
