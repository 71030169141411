import classNames from "classnames";
import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";
import { h } from "@/scripts/helpers/dom/createElement";

class InputPassword extends Plugin {
  defaults() {
    return {
      buttonClassName: "input__button input__button_show-password",
      buttonText: {
        show: "Показать пароль",
        hide: "Скрыть пароль"
      },
      buttonAdditionalClassNames: ["button"],
      buttonToggleAttribute: "data-input-password-showed"
    };
  }

  init() {
    this.renderButton();
  }

  buildCache() {
    const {
      buttonClassName,
      buttonAdditionalClassNames,
      buttonText
    } = this.options;

    this.buttonText = <span className="visuallyhidden">{buttonText.show}</span>;
    this.input = this.element.querySelector("input");

    this.button = (
      <button
        type="button"
        title={buttonText.show}
        disabled={this.isDisabled}
        className={classNames(buttonClassName, buttonAdditionalClassNames)}
      >
        {this.buttonText}
      </button>
    );
  }

  bindEvents() {
    this.button.addEventListener("click", () => this.handleClick());
  }

  get isDisabled() {
    return !!this.input.disabled;
  }

  get isShowed() {
    return this.input.getAttribute("type") === "text";
  }

  handleClick() {
    if (this.isDisabled) {
      this.button.disabled = true;
      return;
    }

    this.button.disabled = false;

    if (!this.isShowed) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.input.setAttribute("type", "text");
    this.button.setAttribute(this.options.buttonToggleAttribute, true);
    this.button.setAttribute("title", this.options.buttonText.hide);
    this.buttonText.innerText = this.options.buttonText.hide;
  }

  hide() {
    this.input.setAttribute("type", "password");
    this.button.setAttribute(this.options.buttonToggleAttribute, false);
    this.button.setAttribute("title", this.options.buttonText.show);
    this.buttonText.innerText = this.options.buttonText.show;
  }

  renderButton() {
    this.element.appendChild(this.button);
    this.hide();
  }
}

export default init(InputPassword, "input-password");
