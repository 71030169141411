import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";

class AccountCardsListItem extends Plugin {
  defaults() {
    return {};
  }

  init() {}

  buildCache() {
    this.dropdown = this.element.querySelector(".dropdown");
  }

  bindEvents() {
    this.events.on("dropdown.open", meta =>
      this.handleDropdownToggle(meta, true)
    );
    this.events.on("dropdown.close", meta =>
      this.handleDropdownToggle(meta, false)
    );
  }

  handleDropdownToggle(meta, toggle) {
    if (meta.element === this.dropdown) {
      if (toggle) {
        this.activate();
      } else {
        this.deActivate();
      }
    }
  }

  activate() {
    this.element.classList.add("is-active");
  }

  deActivate() {
    this.element.classList.remove("is-active");
  }
}

export default init(AccountCardsListItem, "account-cards-list-item");
